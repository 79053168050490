import { useState, useEffect, memo, FC, ReactNode, Children, isValidElement, cloneElement } from 'react';
import { createPortal } from 'react-dom';
import { Button } from '@mui/material';

import { closePopupOnEsc } from 'helpers';

import { successIcon, errorIcon, closeIcon } from 'assets/svg-icons';
import './styles.scss';

const body = document.getElementsByTagName('body')[0];
const el = document.createElement('div');
el.setAttribute('id', 'popup');

interface PopupProps {
  setShowPopup: (arg: boolean) => void;
  showPopup: boolean;
  title?: string;
  message?: string;
  className?: string;
  isSuccessIcon?: boolean;
  children?: ReactNode;
}

const Popup: FC<PopupProps> = ({ setShowPopup, showPopup, title, message, className, isSuccessIcon, children }) => {
  const [element] = useState<HTMLDivElement>(el);

  useEffect(() => {
    if (!showPopup) return;
    closePopupOnEsc(() => setShowPopup(false));

    body.appendChild(element);
    document.body.style.overflow = 'hidden';

    return () => {
      const getEl = document.getElementById('popup');
      getEl && body.removeChild(getEl);
      document.body.style.overflow = 'unset';
    };
  }, [showPopup]);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      return cloneElement(child, { setShowPopup });
    }
    return child;
  });

  return showPopup
    ? createPortal(
        <>
          {childrenWithProps || (
            <div className={`custom-modal-container ${className || ''}`}>
              <div className="modal__body">
                <button className="close__btn" onClick={() => setShowPopup(false)}>
                  {closeIcon}
                </button>
                {typeof isSuccessIcon === 'boolean' && (
                  <span className="icon-wrapper">{isSuccessIcon ? successIcon : errorIcon}</span>
                )}
                <h1 className="modal-title">{title}</h1>
                <h4 className="modal-msg">{message}</h4>
                <div className="panel__btns">
                  <Button onClick={() => setShowPopup(false)} color="primary" variant="contained">
                    OK
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>,
        element,
      )
    : null;
};

export default memo(Popup);
