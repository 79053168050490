import axios from 'axios';
import { getUserToken } from 'helpers';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
});

instance.defaults.headers.common['Authorization'] = getUserToken() || '';

export default instance;
