import { FC, ChangeEvent } from 'react';
import DatePicker from 'react-datepicker';
import { CircularProgress, Button, TextField } from '@material-ui/core';
import moment from 'moment';

import LineChart from 'components/line-chart';
import HtmlTable from 'components/html-table';
import ServerSidePagination from 'components/pagination/server-side-pagination';

import './styles.scss';

interface ScanRequestTableComponentProps {
  tableColumns: any;
  tableData: any;
  loading: boolean;
  toDate: any;
  fromDate: any;
  setToDate: (arg: any) => void;
  setFromDate: (arg: any) => void;
  setSource: (arg: string) => void;
  handleFilter: () => Promise<void>;
  setDayCount: (arg: string) => void;
  lineChartArr: { name: string; users: number }[];
  getChartData: () => Promise<void>;
  getData: () => Promise<void>;
  count: number;
  handlePageNumberChange: (arg: number) => void;
}

const ScanRequestTableComponent: FC<ScanRequestTableComponentProps> = ({
  handlePageNumberChange,
  setFromDate,
  setToDate,
  toDate,
  fromDate,
  setSource,
  tableColumns,
  tableData,
  loading,
  handleFilter,
  lineChartArr,
  setDayCount,
  getChartData,
  getData,
  count,
}) => {
  const handleFromDate = (date: Date) =>
    date ? setFromDate(moment(date).format('YYYY-MM-DDT00:00:00.000')) : setFromDate(null);

  const handleToDate = (date: Date) =>
    date ? setToDate(moment(date).format('YYYY-MM-DDT23:59:59.999')) : setToDate(null);

  return (
    <div style={{ margin: '0' }} className="p-4">
      <div className="row my-4">
        <div className="col-12 col-lg-9">
          <LineChart data={lineChartArr} dataKey="requests" setDayCount={setDayCount} getChartData={getChartData} />
        </div>
        <div className="col-12 col-lg-3 d-flex flex-column">
          <TextField
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSource(e.target.value)}
            label="Source"
            placeholder="source"
            variant="standard"
          />

          <div className="mt-3">
            <label>From date</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={fromDate ? new Date(fromDate) : null}
              onChange={handleFromDate}
              showMonthDropdown
              placeholderText="Select a date"
            />
          </div>
          <div>
            <label>To date</label>
            <DatePicker
              dateFormat="yyyy-MM-dd"
              selected={toDate ? new Date(toDate) : null}
              onChange={handleToDate}
              showMonthDropdown
              placeholderText="Select a date"
              minDate={fromDate && new Date(fromDate)}
            />
          </div>

          <Button onClick={handleFilter} color="primary" className="mt-4" variant="contained">
            Filter
          </Button>

          <div className="mt-5 d-flex align-items-center justify-content-between">
            <span className="total-count-scanrequests-wrapper">
              <pre>Total count: {count}</pre>
            </span>

            <Button onClick={getData} color="primary" variant="contained">
              Reload
            </Button>
          </div>
        </div>
      </div>

      <ServerSidePagination data={tableData} total={count} onPageNum={handlePageNumberChange}>
        {loading ? (
          <div className="d-flex justify-content-center mt-5">
            <CircularProgress />
          </div>
        ) : (
          <HtmlTable data={tableData} columns={tableColumns} tableType="requests" />
        )}
      </ServerSidePagination>
    </div>
  );
};

export default ScanRequestTableComponent;
