import { FC, useEffect, useState } from 'react';
import ScanRequestTableComponent from './scan-request-component';
import moment from 'moment';

import api from 'api';
import './styles.scss';

const tableColumns = [
  { title: 'ID', field: 'id', type: 'numeric' },
  { title: 'IP', field: 'ip' },
  { title: 'User', field: 'user', type: 'numeric' },
  { title: 'Created At', field: 'created_at' },
  { title: 'Scanned at', field: 'scanned_at' },
  { title: 'Status', field: 'status' },
  { title: 'Source', field: 'source' },
];

const ScanRequest: FC = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [count, setCount] = useState(0);
  const [dayCount, setDayCount] = useState('7');
  const [source, setSource] = useState('');
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState<any[]>([]);
  const [lineChartArr, setLineChartArr] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState({});

  const adminListController = new AbortController();
  const lineChartController = new AbortController();

  const getRequestLineChartData = async () => {
    try {
      const res = await api.post(
        '/admin/requests/charts',
        {
          created_at: {
            from: moment()
              .subtract(+dayCount, 'd')
              .format('YYYY-MM-DDT00:00:00.000'),
            to: moment().format('YYYY-MM-DDT23:59:59.999'),
          },
        },
        { signal: lineChartController.signal },
      );
      if (res.data && Object.keys(res.data).length) {
        setLineChartData(res.data);
        // setLineChartLoading(false);
      }
    } catch (error) {
      console.log(error);
      // setLineChartLoading(false);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await api.post(
        '/admin/requests/list',
        {
          page: pageNumber,
          limit: 100,
        },
        { signal: adminListController.signal },
      );
      if (res?.data && Array.isArray(res?.data?.data)) {
        setData(res.data.data);
        setCount(res.data.count);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getRequestLineChartData();

    return () => {
      lineChartController.abort();
      adminListController.abort();
    };
  }, []);

  useEffect(() => {
    getData();
    return () => {
      lineChartController.abort();
    };
  }, [pageNumber]);

  useEffect(() => {
    if (data.length) {
      const arr = data.map((item: any) => ({
        id: item.id || 'N/A',
        ip: item.ip || 'N/A',
        created_at: moment(item.created_at).utc().format('DD-MM-YYYY hh:mm:ss a') || 'N/A',
        scanned_at: moment(item.scanned_at).utc().format('DD-MM-YYYY hh:mm:ss a') || 'N/A',
        source: item.source || 'N/A',
        status: item.status || 'N/A',
        user: item.user || 'N/A',
      }));
      setTableData(arr);
      return;
    }
    setTableData([]);
  }, [data]);

  useEffect(() => {
    if (Object.keys(lineChartData).length) {
      const arr = Object.keys(lineChartData).map((el: string) => {
        return {
          name: el,
          //@ts-ignore
          requests: lineChartData[el],
        };
      });
      arr.pop();
      setLineChartArr(arr);
    }
  }, [lineChartData]);

  const handleFilter = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await api.post('/admin/requests/list', {
        source,
        created_at: {
          from: fromDate,
          to: toDate,
        },
        page: 1,
        limit: 100,
      });

      if (res?.data && Array.isArray(res?.data?.data)) {
        setData(res.data.data);
        setCount(res.data.count);
        setLoading(false);
        setFromDate(null);
        setToDate(null);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlePageNumberChange = (pageNumber: number) => {
    setPageNumber(pageNumber);
  };

  return (
    <div>
      <ScanRequestTableComponent
        handlePageNumberChange={handlePageNumberChange}
        getData={getData}
        lineChartArr={lineChartArr}
        getChartData={getRequestLineChartData}
        setDayCount={setDayCount}
        handleFilter={handleFilter}
        setSource={setSource}
        fromDate={fromDate}
        setFromDate={setFromDate}
        setToDate={setToDate}
        toDate={toDate}
        tableColumns={tableColumns}
        tableData={tableData}
        loading={loading}
        count={count}
      />
    </div>
  );
};

export default ScanRequest;
