import { FC, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { clearCookies } from 'helpers';
import { Button } from '@mui/material';
import { Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import logo from '../../styles/logo.svg';

const links = [
  { name: 'Home', path: '/' },
  { name: 'Users', path: '/users-table' },
  { name: 'Scan requests', path: '/scan-request-table' },
];

const Header: FC = () => {
  const handleLogOut = (): void => {
    clearCookies();
    window.location.reload();
  };

  const [collapsed, setCollapsed] = useState(false);

  return (
    <header className="header">
      <div className="logo-info-wrapper d-none d-md-flex">
        <Link to="/">
          <img src={logo} alt="Candidate checker" style={{ width: '60px', height: 'auto' }} />
        </Link>
        <div className="header-menu-wrapper">
          <nav className="d-flex navs-wrapper">
            {links.map((item: { name: string; path: string }) => (
              <ul key={item.name} className="m-0">
                <li className="li-item">
                  <NavLink className="nav-link" to={item.path} activeClassName="active-link" exact>
                    {item.name}
                  </NavLink>
                </li>
              </ul>
            ))}
          </nav>
        </div>
        <div>
          <Button onClick={handleLogOut} variant="contained">
            Logout
          </Button>
        </div>
      </div>
      <div className=" d-block d-md-none">
        <div className="logo-info-wrapper w-100">
          <Link to="/">
            <img src={logo} alt="Candidate checker" style={{ width: '60px', height: 'auto' }} />
          </Link>
          <div>
            <button
              className={`navbar-toggler ${collapsed ? '' : 'collapsed'}`}
              onClick={() => setCollapsed(!collapsed)}
              type="button"
              data-testid="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="menu_toggle">
                <span className="hamburger">
                  <span />
                  <span />
                  <span />
                </span>
                <span className="hamburger-cross">
                  <span />
                  <span />
                </span>
              </span>
            </button>
          </div>
        </div>

        <div className={`collapsed-menu ${collapsed ? 'd-block' : 'd-none'}`}>
          <div className="header-menu-wrapper">
            <nav className="d-flex navs-wrapper">
              {links.map((item: { name: string; path: string }) => (
                <ul key={item.name} className="m-0">
                  <li className="li-item">
                    <NavLink
                      className="nav-link"
                      to={item.path}
                      activeClassName="active-link"
                      exact
                      onClick={() => setCollapsed(false)}
                    >
                      {item.name}
                    </NavLink>
                  </li>
                </ul>
              ))}
            </nav>
          </div>
          <div className="text-center">
            <Button onClick={handleLogOut} variant="contained">
              Logout
            </Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
