import { FC, useState, FormEvent } from 'react';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Collapse,
  Box,
  Typography,
  Container,
  Alert,
  IconButton,
  CircularProgress,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import api from 'api';
import { setUserToken } from 'helpers';

const theme = createTheme();

const Login: FC = () => {
  const [loading, setLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState({
    msg: 'Something went wrong',
    error: true,
  });

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const data = new FormData(event.currentTarget);

    try {
      const res = await api.post('/admin/login', {
        username: data.get('username'),
        password: data.get('password'),
      });

      if (res.data && res.data.status === 'error') {
        setIsAlertOpen(true);
        setLoading(false);
        setAlertMsg({
          msg: res.data.message,
          error: true,
        });
        return;
      }

      if (res?.data?.status === 'success') {
        setUserToken(res.data.token);
        setLoading(false);
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      setIsAlertOpen(true);
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button variant="contained" type="submit" sx={{ mt: 3, mb: 2 }} fullWidth disabled={loading}>
              {loading && <CircularProgress size={24} />}
              {!loading && 'Login'}
            </Button>
          </Box>
        </Box>
        <Collapse in={isAlertOpen}>
          <Alert
            severity="error"
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={() => setIsAlertOpen(false)}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2, mt: 4 }}
          >
            {alertMsg.msg}
          </Alert>
        </Collapse>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
