import { FC, useState, useEffect, ChangeEvent } from 'react';
import { XAxis, AreaChart, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area } from 'recharts';

import './styles.scss';
import { Button, Input } from '@material-ui/core';

interface LineChartProps {
  data: any;
  dataKey: string;
  setDayCount: (arg: string) => void;
  getChartData: () => Promise<void>;
}

const LineChart: FC<LineChartProps> = ({ data, dataKey, setDayCount, getChartData }) => {
  const [lastDays, setLastDays] = useState('7');

  useEffect(() => {
    setDayCount(lastDays);
  }, [lastDays]);

  const getDataInterval = (length: number): number => Math.round(length / 30);

  return (
    <div className="chart-main-wrapper">
      <div className="chart-wrapper">
        <ResponsiveContainer>
          <AreaChart
            width={500}
            height={500}
            data={data}
            margin={{
              top: 5,
              right: 45,
              left: 0,
              bottom: 5,
            }}
          >
            <XAxis
              interval={data.length <= 15 ? 1 : getDataInterval(data.length) * 3}
              tickMargin={10}
              dataKey="name"
              axisLine={false}
              tickLine={false}
            />
            <YAxis />
            <CartesianGrid strokeDasharray="3 3" />

            <Tooltip />
            <Legend />
            <Area type="monotone" dataKey={dataKey} strokeWidth={2} fill="#82ca9d" stroke="#82ca9d" />
          </AreaChart>
        </ResponsiveContainer>
      </div>

      <div className="chart-bottom-wrapper">
        <span>Last</span>{' '}
        <Input
          type="number"
          value={lastDays}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setLastDays(e.target.value)}
          placeholder="number"
        />
        <span>Days</span>
        <Button variant="contained" onClick={getChartData}>
          Get
        </Button>
      </div>
    </div>
  );
};

export default LineChart;
