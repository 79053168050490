import { FC, useEffect, useState } from 'react';
import { CircularProgress, Dialog } from '@mui/material';
import api from 'api';

import impactImg from 'assets/impact.png';
import './styles.scss';

interface AppProps {
  data: any[];
  columns: any[];
  tableType: 'users' | 'requests';
}

const userPackage = {
  1: 'FREE',
  2: 'MONTH STRIPE',
  3: 'LTD STRIPE',
  4: 'ANNUALLY STRIPE',
  5: 'MONTH PAYPAL',
  6: 'ANNUALLY PAYPAL',
  7: 'LTD PAYPAL',
};

const App: FC<AppProps> = ({ data, columns, tableType }) => {
  const [impactLoading, setImpactLoading] = useState(false);
  const [impactData, setImpactData] = useState<any>([]);
  const [showImpactModal, setShowImpactModal] = useState(false);
  const [impactId, setImpactId] = useState(0);

  const impactQuery = new AbortController();

  const handleGetImpactActionId = async (id: number) => {
    setImpactLoading(true);
    setImpactId(id);
    try {
      const res = await api.post(
        '/admin/impact/user',
        {
          id: `${id}`,
        },
        { signal: impactQuery.signal },
      );
      if (res?.data && res?.data.length > 0) {
        setImpactData(res.data);
      }
      setImpactLoading(false);
    } catch (error) {
      console.log(error);
      setImpactLoading(false);
    }
  };

  useEffect(() => {
    impactData && impactData.length && setShowImpactModal(true);
  }, [impactData]);

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            {columns.map((item: any) => (
              <th key={item.title}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableType === 'users'
            ? data.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.given_name}</td>
                  <td>{item.family_name}</td>
                  <td>{item.email}</td>
                  <td>
                    {impactLoading && impactId === item.id ? (
                      <div
                        style={{
                          width: '40px',
                          height: '40px',
                          background: '#efefef',
                          borderRadius: '5px',
                          margin: 'auto',
                          display: 'flex',
                        }}
                      >
                        <CircularProgress size={20} className="m-auto" />
                      </div>
                    ) : (
                      <>
                        {item.impact_data ? (
                          <div
                            style={{
                              width: '40px',
                              height: '40px',
                              background: '#efefef',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              margin: 'auto',
                              display: 'flex',
                            }}
                            onClick={() => handleGetImpactActionId(item.id)}
                          >
                            <img
                              src={impactImg}
                              style={{ width: '20px', height: '20px', display: 'inline-block', margin: 'auto' }}
                            />
                          </div>
                        ) : (
                          'Other'
                        )}
                      </>
                    )}
                  </td>
                  <td>{item.country}</td>
                  <td>{item.created_at}</td>
                  <td>{item.request_left}</td>
                  {/* @ts-ignore */}
                  <td>{userPackage[item.package]}</td>
                  <td>{item.deleted_at || ''}</td>
                  <td>{item.btn_list || ''}</td>
                </tr>
              ))
            : data.map((item: any) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.ip}</td>
                  <td>{item.user}</td>
                  <td>{item.created_at}</td>
                  <td>{item.scanned_at}</td>
                  <td>{item.status}</td>
                  <td>{item.source}</td>
                </tr>
              ))}
        </tbody>
      </table>
      <Dialog open={showImpactModal} onClose={() => setShowImpactModal(false)} className="impact-dialog">
        <h5>Imact actions</h5>
        <div>
          <span>action IDs:</span> <span>{impactData.map((item: any) => item.id).join(', ')}</span>
        </div>
      </Dialog>
    </div>
  );
};

export default App;
