import Cookies from 'js-cookie';

export const setUserToken = (token: string) => Cookies.set('admin-user-token', token);
export const getUserToken = (): string | undefined => Cookies.get('admin-user-token');
export const setUserName = (username: string) => Cookies.set('admin-username', username);
export const getUserName = (): string | undefined => Cookies.get('admin-username');
const removeUserToken = (): void => Cookies.remove('admin-user-token');
const removeUserName = (): void => Cookies.remove('admin-username');

export const clearCookies = (): void => {
  removeUserToken();
  removeUserName();
};

export const isProduction = () => process.env.REACT_APP_HTTP_HOST === 'https://api.hexometer.com/v2/ql';

export const getCountArr = (length: number) => {
  let newArr = [7];
  const totalTimes = Math.floor(length / 30) + 1;
  for (let i = 1; i <= totalTimes; i++) {
    if (i * 30 <= length) {
      newArr.push(i * 30);
    } else {
      newArr.push(length);
    }
  }
  return newArr;
};

export function isValidEmail(mail: string): boolean {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );
}

export const closePopupOnEsc = (handlePopupStateChange: Function) => {
  const handleEsc = (e: any) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      handlePopupStateChange && handlePopupStateChange();
    }
  };
  window.addEventListener('keydown', handleEsc);

  return () => {
    window.removeEventListener('keydown', handleEsc);
  };
};
