import { Route, Switch, Redirect } from 'react-router-dom';

import Home from 'pages/home';
import Login from 'pages/login';
import UsersTable from 'pages/users-table';
import Scan from 'pages/scan-request-table';
import { getUserToken } from 'helpers';

const privateRoutes = [
  {
    path: '/',
    component: Home,
    exact: true,
  },
  {
    path: '/users-table',
    component: UsersTable,
    exact: true,
  },
  {
    path: '/scan-request-table',
    component: Scan,
    exact: true,
  },
];

const CunstomRoutes = () => {
  const user = getUserToken();

  return (
    <Switch>
      {user ? (
        privateRoutes.map(({ exact, path, component }) => {
          return <Route key={path} exact={!!exact} path={path} component={component} />;
        })
      ) : (
        <Route path="/" component={Login} />
      )}

      <Redirect to="/" />
    </Switch>
  );
};

export default CunstomRoutes;
