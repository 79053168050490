import { FC } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Card } from '@material-ui/core';
import { Button } from '@mui/material';
import { PieChart, Pie, Legend, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import DatePicker from 'react-datepicker';
import { reloadIcon } from 'assets/svg-icons';
import './styles.scss';

const PieComponent: FC<{
  data: any;
  title: string;
  refetchData: any;
  linkTo: string;
  filterObj: any;
  filterBtnClick: any;
  fromDateChange: any;
  toDateChange: any;
}> = ({ data, title, linkTo, refetchData, filterObj, filterBtnClick, fromDateChange, toDateChange }) => {
  const COLORS = [
    '#1f77b4',
    '#f67e2a',
    '#4da02d',
    '#9467bd',
    '#d63c29',
    '#e377c2',
    '#8c564b',
    '#e377c2',
    '#bcbd2d',
    '#7f7f7f',
    '#bcbd2d',
    '#41becf',
    '#2077b4',
    '#f67e2a',
    '#4da02d',
    '#d63c29',
    '#9467bd',
    '#8c564b',
    '#e377c2',
    '#7f7f7f',
    '#41becf',
    '#bcbd2d',
    '#41becf',
    '#2077b4',
    '#f67e2a',
    '#4da02d',
    '#d63c29',
    '#1f77b4',
    '#f67e2a',
    '#4da02d',
    '#9467bd',
    '#d63c29',
    '#e377c2',
    '#8c564b',
    '#e377c2',
    '#bcbd2d',
    '#7f7f7f',
    '#1f77b4',
    '#f67e2a',
    '#4da02d',
    '#9467bd',
    '#d63c29',
    '#e377c2',
    '#8c564b',
    '#e377c2',
    '#bcbd2d',
    '#7f7f7f',
    '#1f77b4',
    '#f67e2a',
    '#4da02d',
    '#9467bd',
    '#d63c29',
    '#e377c2',
    '#8c564b',
    '#e377c2',
    '#bcbd2d',
    '#7f7f7f',
  ];

  const setDate = (days: number) => {
    let date = new Date();
    date.setDate(date.getDate() - days);
    date.setUTCHours(0, 0, 0, 0);
    return date;
  };

  const filterButtons = [
    {
      name: 'Today',
      fromDate: setDate(0),
      toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
    },
    {
      name: 'Yesterday',
      fromDate: setDate(1),
      toDate: new Date(setDate(1).setUTCHours(23, 59, 59, 999)),
    },
    {
      name: 'Last 7 days',
      fromDate: setDate(6),
      toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
    },
    {
      name: 'Last 30 days',
      fromDate: setDate(29),
      toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
    },
    {
      name: 'Last 90 days',
      fromDate: setDate(89),
      toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
    },
    {
      name: 'All',
      fromDate: '2022-04-01',
      toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
    },
  ];

  const handleFromDateChange = (date: Date) => {
    if (date) {
      fromDateChange(moment(date).format('YYYY-MM-DDT00:00:00.000'));
    } else {
      fromDateChange(moment().format('YYYY-MM-DDT00:00:00.000'));
    }
  };

  const handleToDateChange = (date: Date) => {
    if (date) {
      toDateChange(moment(date).format('YYYY-MM-DDT23:59:59.999'));
    } else {
      fromDateChange(moment().format('YYYY-MM-DDT23:59:59.999'));
    }
  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    const chunkSize = 20;
    const arr = [];
    for (let i = 0; i < payload.length; i += chunkSize) {
      const chunk = payload.slice(i, i + chunkSize);
      arr.push(chunk);
    }
    return (
      <div className="d-flex">
        {arr.map((item: any, i: number) => (
          <ul key={i}>
            {item.map((entry: any, index: number) => (
              <li key={`item-${index}`}>
                <span
                  style={{
                    background: entry.color,
                    display: 'inline-block',
                    width: '10px',
                    height: '10px',
                    marginRight: '10px',
                  }}
                ></span>
                {entry.payload.value} {entry.value}
              </li>
            ))}
          </ul>
        ))}
      </div>
    );
  };

  return (
    <Card>
      <div className="pie-card-header">
        <Link to={linkTo}>{title}</Link>
        <div className="reload-icon" onClick={refetchData}>
          {reloadIcon}
        </div>
      </div>
      <ResponsiveContainer width={'90%'} height={650}>
        <PieChart width={800} height={650}>
          <Pie data={data} cx="50%" cy="50%" labelLine={false} outerRadius={'60%'} fill="#8884d8" dataKey="value">
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Legend verticalAlign="middle" align="right" layout="vertical" height={36} content={renderLegend} />
          <Tooltip cursor={{ stroke: 'red', strokeWidth: 2 }} />
        </PieChart>
      </ResponsiveContainer>
      <div className="pie-card-footer">
        <div>
          {filterButtons.map(btnObj => (
            <Button
              onClick={() => filterBtnClick(btnObj)}
              color={filterObj.name === btnObj.name ? 'primary' : 'inherit'}
              key={btnObj.name}
              variant="contained"
              className="m-2"
            >
              {btnObj.name}
            </Button>
          ))}
          <div className="from-to-datepicker row">
            <div className="col-12 col-sm-6 col-md-4">
              <div>
                <div className="no-col-flex row">
                  <div className="col-12 col-sm-3 py-0">
                    <label>From:</label>
                  </div>
                  <div className="col-12 col-sm-9 py-0">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(filterObj.fromDate)}
                      onChange={(date: Date) => handleFromDateChange(date)}
                      showMonthDropdown
                      placeholderText="Select a date"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4">
              <div>
                <div className="row">
                  <div className="col-12 col-sm-3 py-0">
                    <label>To:</label>
                  </div>
                  <div className="col-12 col-sm-9 py-0">
                    <DatePicker
                      dateFormat="yyyy-MM-dd"
                      selected={new Date(filterObj.toDate)}
                      onChange={(date: Date) => handleToDateChange(date)}
                      showMonthDropdown
                      placeholderText="Select a date"
                      minDate={filterObj.fromDate ? new Date(filterObj.fromDate) : null}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
export default PieComponent;
