import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { Edit } from '@mui/icons-material';

import api from 'api';

import Modal from 'components/modal';
import UsersTableComponent from './users-table-component';
import EditModal from './edit-modal';

import './styles.scss';

const tableColumns = [
  { title: 'ID', field: 'id' },
  { title: 'Name', field: 'name' },
  { title: 'First name', field: 'family_name' },
  { title: 'Last name', field: 'given_name' },
  { title: 'Email', field: 'email' },
  { title: 'Impact', field: 'impact_data' },
  { title: 'Country', field: 'country' },
  { title: 'Created At', field: 'created_at' },
  { title: 'Request left', field: 'request_left' },
  { title: 'Package', field: 'package' },
  { title: 'Should be deleted', field: 'deleted_at' },
  { title: 'Edit', field: 'btn_list' },
];

const UsersTable: FC = () => {
  const [loading, setLoading] = useState(true);
  const [lineChartLoading, setLineChartLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [impactIds, setImpactIds] = useState(false);
  const [scheduledToBeDeleted, setScheduledToBeDeleted] = useState(false);
  const [anyPaid, setAnyPaid] = useState(false);

  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [userPackage, setUserPackage] = useState<string | number>('All');
  const [dayCount, setDayCount] = useState('7');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [CSVUrl, setCSVUrl] = useState('');

  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);

  const [data, setData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any[]>([]);
  const [lineChartArr, setLineChartArr] = useState<any[]>([]);
  const [packagesList, setpackagesList] = useState<any[]>([]);
  const [lineChartData, setLineChartData] = useState({});
  const [editData, setEditData] = useState({});

  const adminListController = new AbortController();
  const lineChartController = new AbortController();
  const packageListController = new AbortController();

  const getUserLineChartData = async () => {
    try {
      const res = await api.post(
        '/admin/users/charts',
        {
          created_at: {
            from: moment()
              .subtract(+dayCount, 'd')
              .format('YYYY-MM-DDT00:00:00.000'),
            to: moment().format('YYYY-MM-DDT23:59:59.999'),
          },
        },
        {
          signal: lineChartController.signal,
        },
      );

      if (res.data && Object.keys(res.data).length) {
        setLineChartData(res.data);
        setLineChartLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLineChartLoading(false);
    }
  };

  const getUserPackageList = async () => {
    try {
      const res = await api.get('/admin/packages/list', {
        signal: packageListController.signal,
      });
      if (res?.data && Array.isArray(res.data)) {
        const pcData = res.data.map((item: any) => {
          if (item.name === 'free') {
            return item;
          }
          return { ...item, name: `${item.name} ${item.product_type}` };
        });

        setpackagesList(pcData);

        // setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await api.post(
        '/admin/users/list',
        {
          page: pageNumber,
          limit: 100,
          deleted: scheduledToBeDeleted,
          anyPaid: anyPaid,
          impact: impactIds,
        },
        { signal: adminListController.signal },
      );
      if (res?.data && Array.isArray(res?.data?.data)) {
        setData(res.data.data);
        setLoading(false);
        setCount(res.data.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleFilter = async (): Promise<void> => {
    setLoading(true);
    try {
      const res = await api.post('/admin/users/list', {
        name,
        email,
        created_at: {
          from: fromDate,
          to: toDate,
        },
        package: userPackage === 'All' ? null : userPackage,
        page: 1,
        limit: 100,
        deleted: scheduledToBeDeleted,
        anyPaid: anyPaid,
        impact: impactIds,
      });
      if (res?.data && Array.isArray(res?.data?.data)) {
        setData(res.data.data);
        setCount(res.data.count);
        setLoading(false);
        setFromDate(null);
        setToDate(null);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getUserLineChartData();
    getUserPackageList();

    return () => {
      packageListController.abort();
      lineChartController.abort();
      adminListController.abort();
    };
  }, []);

  useEffect(() => {
    getData();
    return () => {
      lineChartController.abort();
    };
  }, [pageNumber]);

  const handleEditButtonCLick = (item: any) => {
    setEditData(item);
    setShowPopup(true);
  };

  useEffect(() => {
    if (data.length) {
      const arr = data.map((item: any) => ({
        id: item.id || 'N/A',
        name: item.name || 'N/A',
        family_name: item.family_name || 'N/A',
        given_name: item.given_name || 'N/A',
        email: item.email || 'N/A',
        impact_data: item.impact_data,
        country: item.country === 'undefined' ? 'N/A' : item.country,
        created_at: moment(item.created_at).utc().format('DD-MM-YYYY hh:mm:ss a') || 'N/A',
        request_left: item.request_left,
        package: item.package,
        deleted_at: item.deleted_at ? moment(item.deleted_at).utc().format('DD-MM-YYYY hh:mm:ss a') : '',
        btn_list: (
          <div>
            <Button onClick={() => handleEditButtonCLick(item)} color="primary" endIcon={<Edit />} variant="contained">
              Edit
            </Button>
          </div>
        ),
      }));
      setTableData(arr);
      return;
    }
    setTableData([]);
  }, [data]);

  useEffect(() => {
    if (Object.keys(lineChartData).length) {
      const arr = Object.keys(lineChartData).map((el: string) => {
        return {
          name: el,
          //@ts-ignore
          users: lineChartData[el],
        };
      });
      arr.pop();
      setLineChartArr(arr);
    }
  }, [lineChartData]);

  const generateCSVOrDrive = async (driveUpload: boolean): Promise<void> => {
    try {
      const res = await api.post('/admin/user/generatecsv', {
        data: {
          driveUpload,
          name: name || null,
          email: email || null,
          packages: userPackage === 'All' ? null : +userPackage,
        },
      });

      if (res?.status === 200 && res?.data?.csvUrl) {
        if (driveUpload) {
          let isProdUrl = 'https://api.candidatechecker.io';
          // isProdUrl='http://localhost:7000'
          window.open(
            `${isProdUrl}/social-callback/googledrive?admin=admin&report=users&url=${res.data.csvUrl}`,
            '_blank',
          );
          return;
        }

        setCSVUrl(res?.data?.csvUrl);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageNumberChange = (pageNumber: number) => setPageNumber(pageNumber);
  return (
    <>
      <UsersTableComponent
        handlePageNumberChange={handlePageNumberChange}
        getData={getData}
        count={count}
        userPackage={userPackage}
        setUserPackage={setUserPackage}
        packagesList={packagesList}
        lineChartArr={lineChartArr}
        setName={setName}
        setEmail={setEmail}
        handleFilter={handleFilter}
        toDate={toDate}
        setToDate={setToDate}
        fromDate={fromDate}
        setFromDate={setFromDate}
        tableColumns={tableColumns}
        tableData={tableData}
        loading={loading || lineChartLoading}
        setDayCount={setDayCount}
        getChartData={getUserLineChartData}
        impactIds={impactIds}
        setImpactIds={setImpactIds}
        scheduledToBeDeleted={scheduledToBeDeleted}
        setScheduledToBeDeleted={setScheduledToBeDeleted}
        anyPaid={anyPaid}
        setAnyPaid={setAnyPaid}
        generateCSVOrDrive={generateCSVOrDrive}
        CSVUrl={CSVUrl}
        setCSVUrl={setCSVUrl}
      />

      <Modal setShowPopup={setShowPopup} showPopup={showPopup}>
        <EditModal packagesList={packagesList} editData={editData} getData={getData} />
      </Modal>
    </>
  );
};

export default UsersTable;
