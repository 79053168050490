import { FC } from 'react';
import Layout from 'components/layout';
import Routes from 'routes';
import { BrowserRouter } from 'react-router-dom';

const App: FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes />
      </Layout>
    </BrowserRouter>
  );
};

export default App;
