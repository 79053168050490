import { FC, ChangeEvent, useState } from 'react';
import { Select, MenuItem, Button, TextField } from '@material-ui/core';
import { Save } from '@mui/icons-material';

import api from 'api';

import './styles.scss';

interface EditModalProps {
  setShowPopup?: (arg: boolean) => void;
  editData: any;
  packagesList: any[];
  getData: () => Promise<void>;
}

const EditModal: FC<EditModalProps> = ({ setShowPopup, editData, packagesList, getData }) => {
  const [userPackage, setUserPackage] = useState(() => editData.package);
  const [email, setEmail] = useState(() => editData.email);
  const [userId] = useState(() => editData.id);

  const handlePackageUpdate = async () => {
    if (!email || !userId || !userPackage) return;

    try {
      const res = await api.put('/admin/user', {
        data: {
          userId,
          email,
          packages: userPackage,
        },
      });

      if (res?.status === 200 && !res?.data?.error) {
        getData();
        setShowPopup && setShowPopup(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="edit-modal-container">
      <div className="edit-modal-container-body">
        <h5>Edit user</h5>

        <div className="inputs-main-wrapper">
          <div className="d-flex ">
            <div className="selects-wrapper">
              <label htmlFor="Package">Package</label>
              <Select
                className="mt-3"
                id="Package"
                label="Package"
                placeholder="Package"
                value={userPackage}
                onChange={(e: any) => setUserPackage(e.target.value)}
              >
                {packagesList.length &&
                  packagesList.map((item: any) => {
                    return (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}{item.price === 14 || item.price === 9 ? ` - $${item.price}` : ''}
                      </MenuItem>
                    );
                  })}
              </Select>
            </div>

            <div className="selects-wrapper">
              <label className="email-label" htmlFor="Email">
                Email
              </label>
              <TextField
                id="Email"
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
                value={email}
                placeholder="Email"
                variant="standard"
              />
            </div>
          </div>
        </div>
        <div className="btns-block-wrapper">
          <div>
            <Button onClick={handlePackageUpdate} color="primary" endIcon={<Save />} variant="contained">
              Save
            </Button>
            <Button onClick={() => setShowPopup && setShowPopup(false)} color="primary" variant="contained">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditModal;
