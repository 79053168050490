import { FC, useEffect, useState } from 'react';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import api from 'api';
import PieChart from '../../components/pie-chart/index';

interface HomeProps {}

const setDate = (days: number) => {
  let date = new Date();
  date.setDate(date.getDate() - days);
  date.setUTCHours(0, 0, 0, 0);
  return date;
};

const Home: FC<HomeProps> = props => {
  const [userCountries, setUserCountries] = useState<any>(null);
  const [requestStatus, setRequestStatus] = useState<any>(null);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalScans, setTotalScans] = useState(0);
  const [filterObjCountries, setFilterObjCountries] = useState({
    name: 'Today',
    fromDate: setDate(0),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  });
  const [filterObjRequests, setFilterObjRequests] = useState({
    name: 'Today',
    fromDate: setDate(0),
    toDate: new Date(setDate(0).setUTCHours(23, 59, 59, 999)),
  });

  const countriesController = new AbortController();
  const requestStatusController = new AbortController();

  const getCountriesData = async (fromDate: Date, toDate: Date) => {
    setLoadingCountries(true);
    try {
      const res = await api.post(
        '/admin/users/country',
        {
          created_at: {
            from: moment(fromDate).format('YYYY-MM-DDT00:00:00.000'),
            to: moment(toDate).format('YYYY-MM-DDT23:59:59.999'),
          },
        },
        { signal: countriesController.signal },
      );
      if (res?.data) {
        const data = Object.entries(res.data);
        let total = 0;
        const finData =
          data &&
          data.map(item => {
            //@ts-ignore
            total += item[1];
            return { name: item[0], value: item[1] };
          });

        const sorted = finData?.sort((obj1: any, obj2: any) => (obj1.value < obj2.value ? 1 : -1));
        setTotalUsers(total);
        sorted && setUserCountries(sorted);
        setLoadingCountries(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingCountries(false);
    }
  };

  const getRequestStatus = async (fromDate: Date, toDate: Date) => {
    setLoadingRequests(true);
    try {
      const res = await api.post(
        '/admin/requests/status',
        {
          created_at: {
            from: moment(fromDate).format('YYYY-MM-DDT00:00:00.000'),
            to: moment(toDate).format('YYYY-MM-DDT23:59:59.999'),
          },
        },
        { signal: requestStatusController.signal },
      );
      if (res?.data) {
        let total = 0;
        const finData =
          res.data.length &&
          res.data.map((item: { count: string; field: string }) => {
            total += +item.count;
            return { name: item.field, value: +item.count };
          });
        setTotalScans(total);
        finData && setRequestStatus(finData);
        setLoadingRequests(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingRequests(false);
    }
  };

  useEffect(() => {
    getCountriesData(filterObjCountries.fromDate, filterObjCountries.toDate);
    return () => {
      countriesController.abort();
    };
  }, [filterObjCountries]);

  useEffect(() => {
    getRequestStatus(filterObjRequests.fromDate, filterObjRequests.toDate);
    return () => {
      requestStatusController.abort();
    };
  }, [filterObjRequests]);

  const handleRefetchUsersData = (fromDate?: string, toDate?: string) => {
    const from = fromDate ? new Date(fromDate) : filterObjCountries.fromDate;
    const to = toDate ? new Date(toDate) : filterObjCountries.toDate;

    if (!fromDate && !toDate) {
      getCountriesData(from, to);
      return () => {
        countriesController.abort();
      };
    } else {
      setFilterObjCountries({
        name: '',
        fromDate: from,
        toDate: to,
      });
    }
  };

  const handleRefetchRequestsData = (fromDate?: string, toDate?: string) => {
    const from = fromDate ? new Date(fromDate) : filterObjRequests.fromDate;
    const to = toDate ? new Date(toDate) : filterObjRequests.toDate;

    if (!fromDate && !toDate) {
      getRequestStatus(from, to);
      return () => {
        requestStatusController.abort();
      };
    } else {
      setFilterObjRequests({
        name: '',
        fromDate: from,
        toDate: to,
      });
    }
  };

  return (
    <div className="row">
      <div className="col-12 col-lg-6 countries-chart">
        {userCountries && !loadingCountries ? (
          <PieChart
            data={userCountries}
            title={`Registered users by country - ${totalUsers}`}
            refetchData={() => handleRefetchUsersData()}
            linkTo={'/users-table'}
            filterObj={filterObjCountries}
            filterBtnClick={(val: any) => setFilterObjCountries(val)}
            fromDateChange={(fromDate: string) => handleRefetchUsersData(fromDate)}
            toDateChange={(toDate: string) => handleRefetchUsersData(undefined, toDate)}
          />
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <CircularProgress />
          </div>
        )}
      </div>
      <div className="col-12 col-lg-6">
        {requestStatus && !loadingRequests ? (
          <PieChart
            data={requestStatus}
            title={`Scan requests by status - ${totalScans}`}
            refetchData={() => handleRefetchRequestsData()}
            linkTo={'/scan-request-table'}
            filterObj={filterObjRequests}
            filterBtnClick={(val: any) => setFilterObjRequests(val)}
            fromDateChange={(fromDate: string) => handleRefetchRequestsData(fromDate)}
            toDateChange={(toDate: string) => handleRefetchRequestsData(undefined, toDate)}
          />
        ) : (
          <div className="d-flex justify-content-center mt-5">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
