import { FC, ReactNode } from 'react';
import { getUserToken } from 'helpers';

import Footer from './footer';
import Header from './header';

import './styles.scss';

interface IAppProps {
  children: ReactNode;
}

const Layout: FC<IAppProps> = ({ children }) => {
  const user = getUserToken();
  return (
    <div className="page-container">
      {user && <Header />}
      <div className="content-wrap">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
