export const reloadIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      className="svg-icon"
      d="M13.8064 6.27219C13.6639 6.25647 13.0783 6.19183 12.997 6.18283C12.6022 3.0042 9.89065 0.476273 6.52373 0.476273C2.9181 0.476273 0 3.39404 0 7C0 10.6057 2.91777 13.5237 6.52373 13.5237C8.41816 13.5237 10.1314 12.7163 11.3298 11.4114C11.543 11.1793 11.5276 10.8183 11.2955 10.6051C11.0634 10.3919 10.7023 10.4073 10.4892 10.6394C9.50504 11.711 8.09452 12.3824 6.52373 12.3824C3.55586 12.3824 1.14133 9.96786 1.14133 7C1.14133 4.03214 3.55586 1.6176 6.52373 1.6176C9.16861 1.6176 11.374 3.53524 11.8226 6.05319C11.6639 6.03567 11.8205 6.05295 10.9756 5.95968C10.8089 5.94128 10.6852 6.1107 10.7534 6.26388L11.9015 8.84163C11.9691 8.99339 12.1751 9.01614 12.2742 8.88278L13.9569 6.61751C14.0569 6.48293 13.9731 6.29059 13.8064 6.27219Z"
      fill="#BBC2C5"
    ></path>
  </svg>
);

export const closeIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L11 11" stroke="#A6ADAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M1 11L11 1" stroke="#A6ADAF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export const successIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19" stroke="#2FA84F" strokeWidth="2" />
    <path
      d="M29.6875 15L18.6875 26L11.8125 19.125"
      stroke="#2FA84F"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const errorIcon = (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19" stroke="#EA3D2F" strokeWidth="2" />
    <path d="M13 13L27 27" stroke="#EA3D2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13 27L27 13" stroke="#EA3D2F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);
